<template>
    <div
        @click="handleCommonTitleClick"
        class="shop van-hairline--bottom">
        <div class="shop_name">
            {{ title }}
        </div>
        <slot name="orderStatus" />
    </div>
</template>
<script>
export default {
    name: 'CommonTitle',
    props: {
        title: {
            type: String,
            default: '',
        },
    },
    methods: {
        handleCommonTitleClick() {
            this.$emit('commonTitleClick');
        },
    },
};
</script>
<style lang="scss" scoped>
.shop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;
    padding: 0 18px;
    .shop_name {
        flex: 1;
        font-size: 16px;
        font-weight: 500;
        color: #333;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        &::before {
            content: '';
            display: inline-block;
            width: 4px;
            height: 16px;
            margin-right: 6px;
            background: #f76d32;
            border-radius: 1px;
            position: relative;
            top: 2px;
        }
    }
}

</style>
