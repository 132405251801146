<template>
  <div class="wrapper1">
    <div class="drug_box">
      <div class="tab">
        <div>
          <Swipe
            v-if="detailData.productImageUrl"
            :show-indicators="false"
            :autoplay="3000"
            class="tab_swipe"
            @change="changeSwipe"
          >
            <Swipe-item
              v-for="(item, index) in detailData.productImageUrl"
              :key="index"
            >
              <img :key="item" :src="item" class="swipe_img" />
            </Swipe-item>
          </Swipe>
          <img
            v-else
            :src="require('@img/big_default.png')"
            class="tab_swipe"
          />
        </div>

        <div class="swipe_page">
          <span v-if="detailData.productImageUrl">
            {{ current + 1 }}/{{ detailData.productImageUrl.length }}
          </span>
          <span v-else> {{ 1 }}/{{ 1 }} </span>
        </div>
      </div>
      <div class="statistics">
        <div class="amount_add">
          <div class="num">
            <div class="symbol">￥</div>
            <div class="amount">
              {{
                detailData.salePrice && Number(detailData.salePrice).toFixed(2)
              }}
            </div>

            <!-- <div v-if="detailData.memberPrice > 0" class="member_price">
            <MemberPrice :add-class="1" :price="detailData.memberPrice" />
          </div> -->
          </div>

          <!-- <div class="add">
          <AddSubtract
            v-show="mapDataToAttr(detailData).num"
            :attr="mapDataToAttr(detailData)"
          />
          <img
            v-show="!mapDataToAttr(detailData).num"
            :src="require('@img/add_cart.png')"
            class="add_img"
            @click="addToShoppingCart(mapDataToAttr(detailData))"
          />
        </div> -->
        </div>
        <div class="drug_name">
          <div v-show="detailData.prescription === '1'" class="tag">处方药</div>
          <div v-ellipsis="2" class="name_txt">
            {{ detailData.productName + ' (' + detailData.usualName + ')' }}
          </div>
        </div>
      </div>
      <div class="shipping_address" @click="goShop">
        <div class="left_info">
          <div class="go_shop">
            {{ detailData.fullName }}
          </div>
          <div class="deliver_info">
            <span class="deliver">发货</span
            ><span class="deliver_addr"> {{ detailData.cityStr }}</span>
          </div>
        </div>
        <div class="arrow">
          <img :src="require('@img/arrow_right_grey.png')" alt="" />
        </div>
      </div>
      <div class="common_info">
        <CommonTitle title="商品详情" />
        <div class="info_list">
          <div class="info_item">
            <!-- <span>【通用名称】</span> -->
            <span v-ellipsis="2">【通用名称】 {{ detailData.usualName }}</span>
          </div>
          <div class="info_item">
            <span>【商品名称】 </span>
            <span>{{ detailData.productName }}</span>
          </div>
          <div class="info_item">
            <span>【包装规格】</span>
            <span>{{ detailData.specs }}</span>
          </div>
          <!-- <div class="info_item">
          <span>【 保 质 期 】</span>
          <span>{{ detailData.closedSaleDays }}</span>
        </div> -->
          <div class="info_item">
            <span>【贮藏条件】</span>
            <span>{{ detailData.packaging | filterSaveCondition }}</span>
          </div>
          <div class="info_item">
            <span>【批准文号】</span>
            <span>{{ detailData.permissionNo }}</span>
          </div>
          <div class="info_item">
            <span>【生产厂商】</span>
            <span>{{ detailData.providerName }}</span>
          </div>
          <!-- <div class="info_item add_info_item">
          <div class="left_content">
            【补充说明】
          </div>
          <div
            class="right_content"
            v-html="detailData.content" />
        </div> -->
        </div>
      </div>
      <!-- <div class="common_info mb_18">
      <CommonTitle title="购买须知" />
      <div class="purchase">
        <p>1. 选购处方药，需上传处方单图片</p>
        <p>2. 订单满{{ packageMailAmount }}元包邮</p>
        <p>
          3. 如需客服协助，请致电热线<a :href="'tel:' + '400 052 3990'"
            >400 052 3990</a
          >
        </p>
        <p>4. 人工客服工作时间：周一~周五09:00-18:00</p>
      </div>
    </div> -->
      <!-- <div class="car" /> -->
      <!-- <rightIcon :current="'2'" /> -->
      <!-- <Shopping-cart
      v-if="detailData.merchantCode"
      :merchant-code="detailData.merchantCode"
    /> -->
    </div>
    
    <div class="bottom_url" v-show="detailData.fullName === '沈阳三合缘博爱药房有限公司'">
      沈阳三合缘博爱药房有限公司&nbsp;<a href="https://beian.miit.gov.cn/"
        >辽ICP备2021001219号-1</a
      >
    </div>
    <div class="bottom_url" v-show="detailData.fullName === '沈阳三合缘药房有限公司'">
      沈阳三合缘药房有限公司&nbsp;<a href="https://beian.miit.gov.cn/"
        >辽ICP备2021001222号-1</a
      >
    </div>

  </div>
</template>

<script>
import { Swipe, SwipeItem } from 'vant';
import AddSubtract from '@/components/business/add-subtract';
import CommonTitle from '@/components/order/CommonTitle';
// import rightIcon from '@/components/right/index';
import ShoppingCart from '@/components/shopping-cart';
// import MemberPrice from '@/components/member-price';

import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import api from '@/api';
import WxTool from '@/utils/wx-tool';
import Configs from '@/config';

export default {
  name: 'DrugShy',
  components: {
    Swipe,
    SwipeItem,
    AddSubtract,
    CommonTitle,
    // rightIcon,
    ShoppingCart,
    // MemberPrice,
  },
  filters: {
    filterSaveCondition(value) {
      if (value === '1') {
        return '常温';
      }
      if (value === '2') {
        return '阴凉';
      }
      if (value === '3') {
        return '冷链';
      }
      if (value === '4') {
        return '冷冻';
      }
    },
  },
  data() {
    return {
      current: 0,
      detailData: {},
    };
  },
  computed: {
    ...mapState({
      shoppingList: (store) => store.shoppingCart.list,
    }),
    ...mapGetters('shoppingCart', ['mapDataToAttr']),
    packageMailAmount() {
      if (this.defaultSource !== '') {
        return Configs.postageConfig[this.defaultSource].packageMailAmount;
      }
      return 168;
    },
    defaultSource() {
      return localStorage.getItem('defaultSource') || '';
    },
  },
  created() {
    console.log('created');
    // this.getDetail();
    this.detailData = this.$route.params;
  },

  mounted () {
    this.host = window.location.host
    console.log('this.host', this.host)
  },

  watch: {
    $route (val1, val2) {
      console.log('val1', val1)
      // this.host = 
    }
  },

  methods: {
    ...mapMutations('merchantInfo', ['addMerchantInfo']),
    ...mapActions('shoppingCart', ['updateCart']),
    changeSwipe(index) {
      this.current = index;
    },
    getDetail() {
      api.getGoodsDetail({ goodsId: this.$route.params.id }).then((res) => {
        if (res.code === '0') {
          if (this.checkStr(res.data.pharmacyInfo.provinceStr)) {
            res.data.pharmacyInfo.cityStr = res.data.pharmacyInfo.provinceStr;
          }

          this.detailData = res.data;
          // this.detailData.content = '<h2><span style="background-color: rgb(139, 170, 74);">测试富文本编辑器11111223</span></h2><p><span style="font-style: italic;">水<span style="text-decoration-line: underline;">电费费</span></span></p><p><img src="http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/50/pcmoren_huaixiao_org.png" alt="[坏笑]" data-w-e="1"><span style="font-style: italic;"><span style="text-decoration-line: underline;"><br></span></span></p><p><img src="https://test4sapioss-1253856364.cos.ap-beijing.myqcloud.com/JPG/202102/15653708-3AD7-44F8-A057-FF41CB3C8C9B.JPG" style="max-width:100%;">2222第三方</p><p></p><ul><li><img src="https://test4sapioss-1253856364.cos.ap-beijing.myqcloud.com/jpg/202102/IMG_5743.jpg" style="max-width: 100%;"><a href="https://www.baidu.com" target="_blank">电饭锅和</a><br></li><li><table border="0" width="100%" cellpadding="0" cellspacing="0"><tbody><tr><th>1111&nbsp;</th><th>2222&nbsp;</th></tr><tr><td>&nbsp;啊啊啊啊</td><td>&nbsp;吃炒菜</td></tr></tbody></table><p><br></p></li></ul><p></p>';
          document.title = this.detailData.pharmacyInfo.fullName;
          // 缓存药店名称和code
          const param = {
            drugstoreCode: this.detailData.pharmacyInfo.groupCode,
            name: this.detailData.pharmacyInfo.fullName,
          };
          this.addMerchantInfo(param);

          // 分享参数更新
          const instance = WxTool.getInstance();
          const shareParam = {
            desc: res.data.productName + res.data.usualName,
          };
          if (res.data.productImageUrl) {
            const [img] = res.data.productImageUrl;
            shareParam.imgUrl = img;
          }
          instance.onShare(shareParam);
        }
      });
    },
    checkStr(str) {
      switch (str) {
        case '北京市':
          return true;
        case '天津市':
          return true;
        case '上海市':
          return true;
        case '重庆市':
          return true;
        default:
          return false;
      }
    },
    goShop() {
      this.$router.push({
        name: 'store-shy',
        // query: { merchantCode: this.detailData.merchantCode },
        params: {
          merchantCode: this.detailData.merchantCode,
        },
      });
    },
    addToShoppingCart(data) {
      this.updateCart({
        ...data,
        operateType: 'ADD',
        count: 1,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.drug_box {
  // padding-bottom: 50px;
  background: #f5f7fa;
  .tab {
    padding: 0;
    position: relative;
    .tab_swipe {
      width: 100%;
      height: 256px;
      .swipe_img {
        width: 100%;
        height: 100%;
      }
    }
    .swipe_page {
      position: absolute;
      bottom: 16px;
      right: 0;
      width: 50px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      color: #fff;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 100px 0 0 100px;
      font-size: 12px;
      font-weight: 400;
    }
  }
  .statistics {
    background: #fff;
    padding: 16px 18px;
    margin-bottom: 8px;
    .amount_add {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 9px;
      .num {
        display: flex;
        color: #e94947;
        position: relative;
        .symbol {
          width: 9px;
          height: 22px;
          position: absolute;
          top: 14px;
          font-size: 16px;
          font-weight: 500;
          color: #e94947;
        }

        .amount {
          height: 38px;
          font-size: 28px;
          font-weight: 500;
          line-height: 38px;
          margin-left: 16px;
        }
      }
      .member_price {
        margin-left: 12px;
        display: flex;
        align-items: center;
      }
      .add {
        padding-top: 6px;
        .add_img {
          width: 108px;
          height: 28px;
        }
      }
    }
    .drug_name {
      font-size: 14px;
      font-weight: 500;
      color: #333;
      line-height: 20px;
      position: relative;
      display: flex;
      flex-direction: row;
      .tag {
        // position: absolute;
        // left: 0;
        // bottom: 0;
        font-size: 12px;
        width: 44px;
        height: 16px;
        line-height: 16px;
        color: #fff;
        text-align: center;
        background: url('~@img/tag_bg.png') no-repeat;
        background-size: contain;
        margin-right: 5px;
      }
      .name_txt {
        flex: 1;
      }
    }
  }
  .shipping_address {
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 15px 8px 18px;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 8px;
    .left_info {
      flex: 1;
      .go_shop {
        // height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #333;
        line-height: 20px;
        &::before {
          content: '';
          display: inline-block;
          width: 16px;
          height: 16px;
          position: relative;
          top: 3px;
          background: url('~@img/icon_store.png') no-repeat;
          background-size: 16px 16px;
          margin-right: 3px;
        }
      }
      .deliver_info {
        height: 17px;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        padding-bottom: 4px;
        &::before {
          content: '';
          display: inline-block;
          width: 17px;
          height: 17px;
          position: relative;
          top: 4px;
          background: url('~@img/icon_location.png') no-repeat;
          background-size: 17px 17px;
          margin-right: 2px;
        }
        .deliver {
          margin-right: 6px;
          color: #62636c;
        }
        .deliver_addr {
          color: #333;
        }
      }
    }
    .arrow {
      img {
        width: 12px;
        height: 12px;
        margin-top: 16px;
      }
    }
  }
  .common_info {
    margin-bottom: 8px;
    background: white;
    .info_list {
      padding: 18px 18px 10px;
      .info_item {
        line-height: 20px;
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #62636c;
      }
      .add_info_item {
        .left_content {
          width: 86px;
        }
        .right_content {
          min-width: 0;
          flex: 1;
          line-height: 20px;
          font-size: 10px;
          padding: 8px 8px 0;
        }
      }
    }
    .purchase {
      padding: 16px 18px;
      font-size: 14px;
      font-weight: 400;
      color: #62636c;
      p {
        line-height: 22px;
      }
    }
  }
  .mb_18 {
    margin-bottom: 18px;
  }
  .car {
    height: 50px;
    background: #262726;
    border-radius: 25px;
  }
}

.bottom_url {
  height: 50px;
  line-height: 50px;
  background: #fff;
  text-align: center;
  a {
    color: rgb(29, 29, 29)
  }
}

/deep/ .right_content table {
  border-collapse: collapse;
}
/deep/ .right_content table td {
  border: 1px solid #62636c !important;
}
/deep/ .right_content table tbody tr th {
  border: 1px solid #62636c !important;
}
</style>
